import React from "react";
import {
  FooterWrapper,
  FooterTop,
  FooterBottom,
  Container,
  Row,
  ListsRow,
  Column,
  Widget,
  Title,
  List,
  ListItemTag,
  ListItem,
  SocialIcons,
  SocialIconTags,
  Copyright,
  CreatedAt,
} from "./styles";
// router //
import { Link } from "react-router-dom";
// icons //
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaYoutube,
  FaTiktok,
} from "react-icons/fa";

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterTop>
        <Container>
          <Row>
            <ListsRow>
              <Column width="auto">
                <Widget>
                  <Title>Über Joinpuya</Title>
                  <List>
                    <ListItem>
                      <Link to="/uber-mich">
                        <ListItemTag>Wer bin ich?</ListItemTag>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/personal-training">
                        <ListItemTag>Was mache ich?</ListItemTag>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <a href="mailto:fitness@joinpuya.de">
                        <ListItemTag>Kontakt</ListItemTag>
                      </a>
                    </ListItem>
                  </List>
                </Widget>
              </Column>
              <Column width="auto">
                <Widget>
                  <Title>Info</Title>
                  <List>
                    <ListItem>
                      <Link to="/impressum">
                        <ListItemTag>Impressum</ListItemTag>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/datenshutz">
                        <ListItemTag>Datenschutz</ListItemTag>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/agb">
                        <ListItemTag>AGB</ListItemTag>
                      </Link>
                    </ListItem>
                  </List>
                </Widget>
              </Column>
            </ListsRow>
            <Column width="auto">
              <Widget>
                <Title>Finde mich:</Title>
                <SocialIcons>
                  <SocialIconTags href="https://www.instagram.com/joinpuya?igsh=NHIydDh0c3JhbTBx" aria-label="Visit joinpuya's Instagram profile">
                    <FaInstagram />
                  </SocialIconTags>
                  <SocialIconTags href="https://www.tiktok.com/@joinpuya" aria-label="Visit joinpuya's TikTok profile">
                    <FaTiktok />
                  </SocialIconTags>
                  <SocialIconTags href="http://www.youtube.com/@joinpuya?si=_012_HyPtMf88sVI" aria-label="Visit joinpuya's Youtube profile">
                    <FaYoutube />
                  </SocialIconTags>
                  <SocialIconTags href="https://facebook.com/profile.php?id=61552566826046" aria-label="Visit joinpuya's Facebook profile">
                    <FaFacebook />
                  </SocialIconTags>
                  <SocialIconTags href="https://www.linkedin.com/in/pouya-pourebrahim-045239301/" aria-label="Visit joinpuya's Linkedin profile">
                    <FaLinkedin />
                  </SocialIconTags>
                </SocialIcons>
              </Widget>
            </Column>
          </Row>
        </Container>
      </FooterTop>
      <FooterBottom>
        <Container>
          <Row>
            <Column width="auto">
              <Copyright>© Joinpuya 2025 All rights reserved</Copyright>
            </Column>
            <CreatedAt>Made with ♥️ @ Joinpuya</CreatedAt>
          </Row>
        </Container>
      </FooterBottom>
    </FooterWrapper>
  );
};

export default Footer;
