import styled from "styled-components";

export const FooterWrapper = styled.footer`
  background: #0a0a3f;
`;

export const FooterTop = styled.div`
  position: relative;
  overflow-x: hidden;
`;

export const FooterBottom = styled.div`
  padding-top: 5px;
  padding-bottom: 50px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7f88a6;
  padding: 27px 0px;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

export const ListsRow = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Column = styled.div<{ width: string }>`
  padding: 15px;
  flex: 0 0 ${({ width }) => width};
  max-width: ${({ width }) => width};
  @media screen and (max-width: 960px) {
   padding: 5px 15px;
  }
`;

export const Widget = styled.div`
  margin-bottom: 30px;
`;

export const Title = styled.h3`
  margin-bottom: 30px;
  color: #eee;
  font-weight: 600;
  font-size: 18px;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  margin-bottom: 15px;
`;

export const ListItemTag = styled.span`
  color: #eee;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #8f7b45;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  margin-top: 20px;
  }
`;

export const SocialIconTags = styled.a`
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: #ebeef5;
  border: 1px solid #e2e2eb;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  color: #858da8;
  border-radius: 50%;
  transition: background 0.2s linear;
  &:hover {
    background: #8f7b45;
    color: white;
  }
`;

export const Copyright = styled.p`
  margin-bottom: 0;
  font-weight: 400;
  color: #7f88a6;
`;

export const CreatedAt = styled.p`
  @media screen and (max-width: 960px) {
    padding: 15px;
  }
`;
